import { format } from "date-fns";
import React from "react";
import { useGetConfigQuery } from "store/reducers";

import { Button } from "./button";

interface MessageAcknowledgementProps {
  handleAcknowledgeMessage: (messageId: number) => void;
  message: Message;
  sentByCurrentUser: boolean;
}

function MessageAcknowledgement({ message, sentByCurrentUser, handleAcknowledgeMessage }: MessageAcknowledgementProps) {
  const { data: configData } = useGetConfigQuery();

  if (!message.requires_ack) {
    return null;
  }

  const acknowledgeBadge =
    "inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mt-1";

  return (
    <>
      {message.employee_id === configData?.currentEmployee?.id && (
        <div>
          {message.acknowledged_at && (
            <span className={acknowledgeBadge}>
              Acknowledgement sent on {format(new Date(message.acknowledged_at), "MMM d, yyyy, h:mm a")}
            </span>
          )}
          {!message.acknowledged_at && (
            <Button
              colour="accent"
              className="w-100 mt-1"
              size="sm"
              onClick={() => handleAcknowledgeMessage(message.id)}
            >
              Acknowledge Message
            </Button>
          )}
        </div>
      )}

      {sentByCurrentUser && (
        <div>
          {message.acknowledged_at && (
            <span className={acknowledgeBadge}>
              Message acknowledged on {format(new Date(message.acknowledged_at), "MMM d, yyyy, h:mm a")}
            </span>
          )}
          {!message.acknowledged_at && <span className={acknowledgeBadge}>Waiting for acknowledgement</span>}
        </div>
      )}
    </>
  );
}

export default MessageAcknowledgement;
